import './App.scss';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Layout from './Layout'; // Importando o novo Layout
import { Login } from './login/acesso';
import { EsqueciSenha } from './login/esqueci_senha';
import Home from './view/home';
import UserList from './view/user';
import Profile from './view/user/profile';
import EditUser from './view/user/edit_user';
import LocationsList from './view/locations';
import FrequencyList from './view/frequency';
import MeasureList from './view/measure';
import AllocateList from './view/allocate';
import EditLocation from './view/locations/edit_location';
import EditFrequency from './view/frequency/edit_frequency';
import EditMeasure from './view/measure/edit';
import FlowchartList from './view/flowchart';
import Editor from './view/flowchart/editor';
import EditFlowchart from './view/flowchart/edit_flowchart';
import AllocateOperador from './view/flowchart/allocate_operador';
import ManageForm from './view/input';
import ListElements from './view/input/elements';
import Element from './view/input/elements/element';
import FillForm from './view/input/formulario';
import Loading from './components/loading';

// Definir basename no segundo argumento do createBrowserRouter
const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <Layout><Home /></Layout>
    },
    {
      path: "/login",
      element: <Login />
    },
    {
      path: "/esquecisenha",
      element: <EsqueciSenha />
    },
    {
      path: "/usuarios",
      element: <Layout><UserList /></Layout>
    },
    {
      path: "/perfil",
      element: <Layout><Profile /></Layout>
    },
    {
      path: "/usuarios/:id",
      element: <Layout><EditUser /></Layout>
    },
    {
      path: "/locais",
      element: <Layout><LocationsList /></Layout>
    },
    {
      path: "/locais/:id",
      element: <Layout><EditLocation /></Layout>
    },
    {
      path: "/frequencias",
      element: <Layout><FrequencyList /></Layout>
    },
    {
      path: "/frequencias/:id",
      element: <Layout><EditFrequency /></Layout>
    },
    {
      path: "/unidades",
      element: <Layout><MeasureList /></Layout>
    },
    {
      path: "/unidades/:id",
      element: <Layout><EditMeasure /></Layout>
    },
    {
      path: "/alocacoes",
      element: <Layout><AllocateList /></Layout>
    },
    {
      path: "/processos",
      element: <Layout><FlowchartList /></Layout>
    },
    {
      path: "/processos/:id",
      element: <Layout><EditFlowchart /></Layout>
    },
    {
      path: "/processos/editor/:id",
      element: <Layout><Editor /></Layout>
    },
    {
      path: "/processos/gerenciarpermissaooperador/:id",
      element: <Layout><AllocateOperador /></Layout>
    },
    {
      path: "/gestaoformularios",
      element: <Layout><ManageForm /></Layout>
    },
    {
      path: "/gestaoformularios/processo/:id",
      element: <Layout><ListElements /></Layout>
    },
    {
      path: "/gestaoformularios/processo/elemento/:id",
      element: <Layout><Element /></Layout>
    },
    {
      path: "/gestaoformularios/processo/elemento/formulario/:id",
      element: <Layout><FillForm /></Layout>
    },
    {
      path: "/gestaoformularios/processo/elemento/editarpropriedade/:id",
      element: <Layout><FillForm /></Layout>
    },
    {
      path: "/loading",
      element: <Layout><Loading /></Layout>
    },
  ],
  {
    basename: '/', // Aqui é onde você configura o basename para o seu app
  }
);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
