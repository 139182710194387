import { Button } from "react-bootstrap";
import "./index.scss";
import { useState } from "react";
import CreateProperties from "./create";
import CardElement from "./card";


const Properties = (element_id) => {
    const [showCreateProperties, setShowCreateProperties] = useState(false);
    const [refresh, setRefresh] = useState(false); 

    const handleShow = () => setShowCreateProperties(true);
    const handleClose = () => setShowCreateProperties(false);

    const handleCreateProperty = () => {
        // Função chamada após criar uma nova propriedade
        setRefresh(prev => !prev); // Força o re-render do CardElement
        handleClose();
     };

    return (
        <div>
            <div className="container_properties_title">
                <h2>Propriedades</h2>
                <Button variant="outline-primary" onClick={handleShow}>Nova propriedade</Button>
            </div>
            <CreateProperties show={showCreateProperties} handleClose={handleClose} element_id={element_id} onCreateProperty={handleCreateProperty}/>
            <div className="container_properties_cards">
                <CardElement element_id={element_id} refresh={refresh} setRefresh={setRefresh} />
            </div>
        </div>
    );
}  

export default Properties;