import React, { useEffect, useState } from 'react';
import AuthService from '../../../../services/auth.service';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Alert, Button, Container, Row } from 'react-bootstrap';
import InputsService from '../../../../services/inputs';
import "../../index.scss";
import Convert from '../../../../utils/convert';
import FormPIcon from '../../../../components/icons/form_p';
import CloseEventIcon from '../../../../components/icons/close_event';
import DataTable from 'react-data-table-component';
import EditIcon from '../../../../components/icons/edit';
import BackIcon from '../../../../components/icons/back';

const Element = () => {
  const navigate = useNavigate();
  const currentUser = AuthService.getCurrentUser();
  const [roleUser, setRoleUser] = useState('');
  const [dataElement, setDataElement] = useState('');
  const [erros, setErros] = useState({});
  const [alert, setAlert] = useState(false);
  const elementData = JSON.parse(localStorage.getItem("elementData"));
  const elementsFlowData = JSON.parse(localStorage.getItem("elementsFlowData"));

  const id = useParams();

  const findElementName = (target, elements) => {
    const found = elements.find(
      element =>
        element.element_id === target.element_id &&
        element.flowchart_id === parseInt(target.flowchart_id) 
    );
    return found ? found.name : "Elemento não encontrado";
  };

  useEffect(() => {
    const fetchRole = async () => {
      try {
        const role = await AuthService.verifyRole();
        if (role.status === 200) {
          const level = role.data.data.roles;
          setRoleUser(level);
        } else {
          navigate('/login');
        }
      } catch (error) {
        console.error("Failed to fetch role:", error);
        navigate('/login');
      }
    };
    const fetchElementItems = async () => {
      const element_data = JSON.parse(localStorage.getItem('elementData'));
      if (element_data) {
        try {
          const Element = await InputsService.getElement(id.id, element_data.element_id, element_data.event_id);
          if (Element.status === 200) {
            setDataElement(Element.data.data);
            localStorage.setItem('propertiesData', JSON.stringify(Element.data.data.event_data));
          }
        } catch (error) {
        }
      }
    };

    fetchRole();
    fetchElementItems();
  }, [navigate, id]);

  useEffect(() => {
    if (currentUser && roleUser) {
      if (roleUser.length === 0 && roleUser.includes("OPERATOR")) {
        navigate('/login');
      }
    } else if (!currentUser) {
      navigate('/login');
    }
  }, [roleUser, currentUser, navigate]);

  if (!dataElement) {
    return <div>Carregando...</div>;
  }
  
  const closeEvent = async () => {
    const element_data = JSON.parse(localStorage.getItem('elementData'));
    if (element_data) {
      try {
        const Element = await InputsService.closeEvent(id.id, element_data.element_id, element_data.event_id);
        if (Element.status === 200) {
          setErros({});
          setAlert(true);
          navigate(-1);

          setTimeout(() => {
            setAlert(false);
          }, 3000);
        } else {
          erros.submit = Element.message;
          setErros(erros);
          setAlert(true);
        }
      } catch (error) {
      }
    }
  }

  console.log("dataElement: ", dataElement)

  const fillForm = () => {
    navigate(`/gestaoformularios/processo/elemento/formulario/${id.id}`);
  }

  const EditProperties = ({ data_id }) => (
    <Link to={`/gestaoformularios/processo/elemento/editarpropriedade/${data_id}`}>
      <EditIcon />
    </Link>
  );
  const columns = [
    {
      name: 'Nome',
      selector: row => row.element_property.name,
      sortable: true,
    },
    {
      name: 'Valor',
      selector: row => row.value,
      cell: (row) =>
        row.element_property.data_type === 'DATE'
          ? Convert.convertDataBR(row.value) // Exibe data formatada
          : row.value, // Exibe valor normal
      sortable: true,
    },
    {
        name: 'Criado em',
        selector: row => row.create_at,
        cell: row => Convert.convertDataBR(row.create_at),
        sortable: true,
        center: "true",
    },
    {
      name: 'Ações',
      selector: row => row.data_id,
      cell: row => <EditProperties data_id={row.data_id} />,
      width: '90px',
    },
  ];


  const ExpandedComponent = ({ data }) => {
    return (
        <div className='container_datatable_expanded'>
            <p><strong>Descrição:</strong> {data.element_property.description}</p>
        </div>
    );
  };


  const paginationComponentOptions = {
    rowsPerPageText: 'Propriedades por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  };

  const handleBackPage = () => {
    console.log("Back");
    navigate(-1);
  };

  const Back = ({ onClick }) => (
    <button onClick={onClick} style={{ background: 'none', border: 'none' }}>
      <BackIcon />
    </button>
  );
  return (
    <Container className='container-custom-fluid'>
      <Row>
      <header className='header  header_operator'>
      <div>
              {/* <Breadcrumbs />  */}
              <Back onClick={handleBackPage} />
              <h1>{findElementName(elementData, elementsFlowData)}</h1>
        </div>
          <div className='container_header_close_event'>
            {roleUser.includes("OPERATOR") && (
              <>
                <Button variant="outline-danger" size="md" onClick={closeEvent}><CloseEventIcon />Fechar evento</Button>
                <Button className="container_header_form"variant="primary" size="md" onClick={fillForm}><FormPIcon />Preencher formulário</Button>
              </>
            )}
          </div>
      </header>
        <section className='container_card_element element'>
          <h2>Entradas</h2>
          <div className='container_cards_elements'>
            {dataElement.flow_inputs.length > 0 ? (
              dataElement.flow_inputs.map((input, index) => (
                <div className='container_cards_element'>
                  <div key={index} className={input.production_input.is_used ? 'card_flowchart card_open' : 'card_flowchart'}>
                    <div className="card_title">
                      <h2 className="flowchart-name">{input.production_input.name}</h2>
                    </div>
                    <div className="card_body">
                      <span><strong>Quantidade:</strong> {input.production_input.quantity}{input.production_input.measurement.symbol}</span>
                      <span><strong>Data de Criação:</strong> {Convert.convertDataBR(input.production_input.create_at)}</span>
                      <span><strong>Usado:</strong> {input.production_input.is_used ? 'Sim' : 'Não'}</span>
                      <span><strong>Aprovado:</strong> {input.production_input.approved ? 'Sim' : 'Não'}</span>
                    </div>
                    {/* <div className="card-action">
                      {input.production_input.is_used ? (
                        <>
                          <div>
                            
                          </div>
                        </>
                      ) : null}
                    </div> */}
                  </div>
                </div>
              ))
            ) : (
              <p>Sem entradas registradas.</p>
            )}
          </div>

        <h2>Saídas</h2>
        <div className='container_cards_elements'>
            {dataElement.flow_outputs.length > 0 ? (
              dataElement.flow_outputs.map((output, index) => (
                <div className='container_cards_element'>
                  <div key={index} className='card_flowchart'>
                    <div className="card_title">
                      <h2 className="flowchart-name">{output.name}</h2>
                    </div>
                    <div className="card_body">
                      <span><strong>Quantidade:</strong> {output.quantity}{output.measurement.symbol}</span>
                      <span><strong>Data de Criação:</strong> {Convert.convertDataBR(output.create_at)}</span>
                      <span><strong>Usado:</strong>  {output.is_used ? 'Sim' : 'Não'}</span>
                      <span><strong>Aprovado:</strong> {output.approved ? 'Sim' : 'Não'}</span>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>Sem saídas registradas.</p>
            )}
          </div>

          <h2>Propriedades</h2>
            <DataTable
              columns={columns}
              data={dataElement.event_data}
              pagination
              responsive
              striped
              paginationComponentOptions={paginationComponentOptions}
              expandableRows
              expandableRowsComponent={ExpandedComponent}
              noDataComponent={<div>Nenhuma propriedade disponível ou cadastrado</div>}
            />
          <div className='close_event_alert'>
            {alert && !erros.submit && <Alert variant="success" onClose={() => setAlert(false)} dismissible show={alert}>Eventos fechados com sucesso!</Alert>}
            {alert && erros.submit && <Alert variant="danger" onClose={() => setAlert(false)} dismissible show={alert}>{erros.submit}</Alert>}
          </div>
        </section>
      </Row>
    </Container>
  );
};

export default Element;