import React, { useEffect, useState } from 'react';
import AuthService from '../../services/auth.service';
import { useNavigate } from 'react-router-dom';
import { Alert, Button, Container, Row } from 'react-bootstrap';
import Breadcrumbs from '../../components/breadcrumbs';
import LocationService from '../../services/location';
import CustomSelect from '../../components/select';
import UserService from '../../services/user';
import ExchangeIcon from '../../components/icons/exchange';
import "./index.scss";
import AllocateService from '../../services/allocate';
import ReactSelect from 'react-select';
import Loading from '../../components/loading';

const AllocateList = () => {
  const navigate = useNavigate();
  const currentUser = AuthService.getCurrentUser();
  const [roleUser, setRoleUser] = useState('');
  const [dataLocations, setDataLocations] = useState([]);
  const [dataUsers, setDataUsers] = useState([]);
  const [alocation, setaLocation] = useState([]);
  const [aUser, setaUser] = useState('');
  const [erros, setErros] = useState({});
  const [alert, setAlert] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchLocations = async () => {
      const location = await LocationService.getLocations();
      if (location) {
        let localSelect = location.data.data;
        let transformedLocations = localSelect.map(location => ({
          label: location.name,
          value: location.local_id,
        }));
        setDataLocations(transformedLocations)
      } else {
        setErros('Não foi possível obter os usuários.');
      }
    };

    const fetchUsers = async () => {
      const user = await UserService.getUsers();
      if (user) {
        let userSelect = user.data.data
        let transformedUsers = userSelect.map(user => ({
          label: user.name,
          value: user.user_id,
        }));
        setDataUsers(transformedUsers)
      } else {
        setErros('Não foi possível obter os usuários.');
      }
    };
  
    fetchUsers();
    fetchLocations();
  }, [navigate]);

  useEffect(() => {
    const fetchRole = async () => {
      try {
        const role = await AuthService.verifyRole();
        if (role.status === 200) {
          const level = role.data.data.roles;
          setRoleUser(level);
        } else {
          navigate('/login');
        }
      } catch (error) {
        console.error("Failed to fetch role:", error);
        navigate('/login');
      }
    };

    fetchRole();
  }, [navigate]);

  useEffect(() => {
    if (currentUser && roleUser) {
      if (roleUser.length === 0 && roleUser.includes("MASTER")) {
        navigate('/login');
      }
    } else if (!currentUser) {
      navigate('/login');
    }
  }, [roleUser, currentUser, navigate]);

  const handleAllocate = async () => {
    setLoading(true);
    let erros = {};
    let valid = true;
    let locals = alocation.map(item => item);

    if (!aUser) {
      erros.user = 'O usuário é obrigatório';
      valid = false;
    }

    if(valid) {
      try {
        const response = await AllocateService.newAllocate(
            parseInt(aUser.target.value),
            locals
        );
        if (response.status === 200) {
          setErros({});
          setAlert(true);
          setaLocation([]);
          setaUser(null);

          setLoading(false);
          setTimeout(() => {
            setAlert(false);
          }, 3000);
        } else {
          erros.submit = response.message;
        }
      } catch (error) {
        erros.submit = error.message.data;
        setLoading(false);
      }
    } else {
      setErros(erros);
      // isso está esquisito, não sei a regra, mas está dando alerta de sucesso ao preencher alocar sem informar nada
      setAlert(true);

      setTimeout(() => {
        setAlert(false);
      }, 3000);
    }
    
    if(erros) {
      setErros(erros);
      setAlert(true);
      setLoading(false);
    }
  };

  const isMultic = true;

  const handleChange = (selectedOption) => {
    // Adiciona o name ao chamar o onChange, se existir
    const value = isMultic
      ? selectedOption?.map(option => option.value)
      : selectedOption ? selectedOption.value : "";

    // Atualiza o estado
    setaLocation(value);
  };

  return (
    <Container className='container-custom-fluid'>
      {loading && (
        <Loading /> 
      )}
      <Row>
        <header className='header'>
          <div>
                <Breadcrumbs />
                <h1>Alocações</h1>
          </div>
        </header>
        <section>
        <div className='container-panel'>
            <div className='container-panel-select container-panel-allocate'>
              <CustomSelect 
                label="Usuário" 
                options={dataUsers} 
                value={aUser ? aUser.target.value : ''} 
                onChange={setaUser} 
              />
              {erros.user && <span className='texterror'>{erros.user}</span>}

              <div className="input_p container_exchange_icon"> <ExchangeIcon /> </div>
               <div className='select'>
                <label>Local</label>
                <ReactSelect 
                  isMulti={isMultic} // Suporte a múltiplos selecionados
                  options={dataLocations} 
                  value={dataLocations.filter(option => alocation.includes(option.value))} // Mapeia os valores selecionados
                  onChange={handleChange} 
                  placeholder="Digite para buscar..."
                />
              </div>

            </div>

            <div className='container-alerts'>
              {alert && !erros.submit && !erros.user&& <Alert variant="success" onClose={() => setAlert(false)} dismissible show={alert}>Alocações criadas com sucesso!</Alert>}
              {alert && erros.submit && <Alert variant="danger" onClose={() => setAlert(false)} dismissible show={alert}>{erros.submit}</Alert>}
            </div>

            <div className='container-panel-button'>
              <Button variant="primary" onClick={handleAllocate}>
                  Alocar usuário
              </Button>
            </div>
          </div>
        </section>
      </Row>
    </Container>
  );
};

export default AllocateList;