import { useEffect, useState } from "react";
import CloseIcon from "../../../../../../components/icons/close";
import "./index.scss";
import TextArea from "../../../../../../components/textarea";
import CustomInput from "../../../../../../components/input";
import CustomSelect from '../../../../../../components/select';
import MeasurementService from "../../../../../../services/measurement";
import FrequencyService from "../../../../../../services/frequency";
import { Button } from "react-bootstrap";
import { useParams } from "react-router-dom";

const CreateProperties = ({ show, handleClose, element_id, onCreateProperty }) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [active, setActive] = useState(true);
    const [data_type, setDataType] = useState('');
    const [min_value, setMinValue] = useState('');
    const [max_value, setMaxValue] = useState('');
    const [upper_specification, setUperSpecification] = useState('');
    const [lower_specification, setLowerSpecification] = useState('');
    const [measurement_id, setMeasurementId] = useState('');
    const [frequency_id, setFrequencyId] = useState('');
    const [erros, setErros] = useState({});
    const [measures, setDataMeasures] = useState([]);
    const [frequencys, setDataFrequencys] = useState([]);
    const elementId = element_id.element_id;
    const { id } = useParams();


    const optionsActive = [
        { label: 'Ativo', value: true },
        { label: 'Desativado', value: false },
    ];

    const optionsDataType = [
        { label: 'Booleano', value: "BOOL" },
        { label: 'Data', value: "DATE" },
        { label: 'Data e hora', value: "DATETIME" },
        { label: 'Número', value: "NUMBER"},
        { label: 'Número inteiros', value: "INTEGER"},
        { label: 'Texto', value: "TEXT" },
    ];

    const handleChangeMeasurementId = (event) => {
        setMeasurementId(event.target.value);
    };

    const handleChangeFrequencyId = (event) => {
        setFrequencyId(event.target.value);
    };

    const handleChangeDataType = (event) => {
        setDataType(event.target.value);
    };

    const handleChangeActive = (event) => {
        setActive(event.target.value);
    };


    useEffect(() => {
        const fetchLocations = async () => {
          const measure = await MeasurementService.getMeasurements();
          if (measure) {
            let measureSelect = measure.data.data;
            let transformedMeasure = measureSelect.map(measure => ({
              label: measure.name,
              value: measure.measurement_id,
            }));
            setDataMeasures(transformedMeasure)
          } else {
            setErros('Não foi possível obter os usuários.');
          }
        };

        fetchLocations();

        const fetchUsers = async () => {
            const frequency = await FrequencyService.getFrequencys();
            if (frequency) {
              let frequencySelect = frequency.data.data;
              let transformedFrequency = frequencySelect.map(frequency => ({
                label: frequency.name,
                value: frequency.frequency_id,
              }));
              setDataFrequencys(transformedFrequency)
            } else {
              setErros('Não foi possível obter os usuários.');
            }
          };

        fetchUsers();
    }, [show]);


    const handleNewProperties = () => {
        let valid = true;
        let erros = {};
        let propertiesData = localStorage.getItem('properties');
        let propertiesDataArray = [];
        
        if (!name.trim()) {
            erros.name = 'O nome é obrigatório';
            valid = false;
        }

        if (parseInt(max_value) < parseInt(min_value)) {
            erros.max_value = 'O valor máximo não pode ser menor que o valor mínimo';
            valid = false;
        }

        console.log(measurement_id)

        if (!measurement_id) {
            erros.measures = 'A unidade de medida é obrigatório';
            valid = false;
        }

        if (!frequency_id) {
            erros.frequencys = 'A frequência é obrigatório';
            valid = false;
        }

        if (!data_type) {
            erros.data_type = 'O tipo de dado é obrigatório';
            valid = false;
        }
        
        if (valid) {
            setErros([]);
            let newProperties = {
                "property_id": null,
                'element_id': elementId,
                'flowchart_id': id,
                'name': name,
                'description': description ? description : null,
                'min_value': min_value ? min_value : null,
                'max_value': max_value ? max_value : null,
                'lower_specification': lower_specification ? lower_specification : null,
                'upper_specification': upper_specification ? upper_specification : null,
                'measurement_id': measurement_id,
                'frequency_id': frequency_id,
                'data_type': data_type,
                'active': active.value
            }

            if (propertiesData) {
                propertiesDataArray = JSON.parse(propertiesData);
            }

            propertiesDataArray.push(newProperties);
            localStorage.setItem('properties', JSON.stringify(propertiesDataArray));

            onCreateProperty();

            setName('');
            setDescription('');
            setMinValue('');
            setMaxValue('');
            setLowerSpecification('');
            setUperSpecification('');
            setMaxValue('');
            setMeasurementId(null);
            setFrequencyId(null);
            setDataType(null);
            setActive(null);
        } else {
            setErros(erros);
        }
    };
    return (
        <div className={`container_new_create_properties ${!show ? 'container_hide' : ''}`}>
            <div className="container_create_properties__title">
                <h3>Nova proriedade</h3>
                <span onClick={handleClose}><CloseIcon /></span>
            </div>
            <div className="container_element_inputs">
                <div>
                    <CustomInput
                        label="Nome"
                        type="text"
                        placeholder="Nome"
                        value= {name}
                        onChange= {(e) => setName(e.target.value)}
                    />
                    {erros.name && <span className='texterror'>{erros.name}</span>}
                </div>
                <div>
                    <TextArea 
                        label="Descrição"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Descrição"
                        rows={2}
                    />
                </div>
                <div className="container_element_inputs__two">
                    <div>
                        <CustomInput
                            label="Valor minímo"
                            type="number"
                            placeholder="-7"
                            value= {min_value}
                            onChange= {(e) => setMinValue(e.target.value)}
                        />
                        {erros.min_value && <span className='texterror'>{erros.min_value}</span>}
                    </div>
                    <div>
                        <CustomInput
                            label="Valor máximo"
                            type="number"
                            placeholder="100"
                            value= {max_value}
                            onChange= {(e) => setMaxValue(e.target.value)}
                        />
                        {erros.max_value && <span className='texterror'>{erros.max_value}</span>}
                    </div>
                </div>
                <div className="container_element_inputs__two">
                    <div>
                        <CustomInput
                            label="Especificação inferior"
                            type="text"
                            placeholder=""
                            value= {lower_specification}
                            onChange= {(e) => setLowerSpecification(e.target.value)}

                        />
                        {erros.lower_specification && <span className='texterror'>{erros.lower_specification}</span>}
                    </div>
                    <div>
                        <CustomInput
                            label="Especificação superior"
                            type="text"
                            placeholder=""
                            value= {upper_specification}
                            onChange= {(e) => setUperSpecification(e.target.value)}
                        />
                        {erros.upper_specification && <span className='texterror'>{erros.upper_specification}</span>}
                    </div>
                </div>
             </div>
             <div className='container-panel-select'>
                <CustomSelect
                    label="Unidades de medidas" 
                    options={measures} 
                    value={measurement_id} 
                    onChange={handleChangeMeasurementId} 
                />
                {erros.measures && <span className='texterror'>{erros.measures}</span>}
            </div>
            <div className='container-panel-select'>
                <CustomSelect
                    label="Frequência" 
                    options={frequencys} 
                    value={frequency_id} 
                    onChange={handleChangeFrequencyId} 
                />
                {erros.frequencys && <span className='texterror'>{erros.frequencys}</span>}
            </div>
            <div className='container-panel-select'>
                <CustomSelect
                    label="Tipo de dado" 
                    options={optionsDataType} 
                    value={data_type} 
                    onChange={handleChangeDataType} 
                />
                {erros.data_type && <span className='texterror'>{erros.data_type}</span>}
            </div>
            <div className='container-panel-select'>
                <CustomSelect
                    label="Situação" 
                    options={optionsActive} 
                    value={active} 
                    onChange={handleChangeActive} 
                />
            </div>
            <div className='container-panel-button save_process'>
              <Button variant="primary" onClick={handleNewProperties}>
                Adicionar propriedade
              </Button>
            </div>
        </div>
    );
}


export default CreateProperties;